import React, { useState, Fragment, useRef, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import Button from "../Button";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { gaEvent, encode } from "../../helpers";
import "react-datepicker/dist/react-datepicker.css";

import "react-phone-number-input/style.css";

const form_name = "freeze-membership";

const freeze_reasons = [
  "Vacation",
  "Work Travel",
  "Injury",
  "Illness",
  "Motivation",
  "Weather",
  "Financial",
  "Other",
];

const freezeOptions = {
  "1 Week": { weeks: 1 },
  "2 Weeks": { weeks: 2 },
  "3 Weeks": { weeks: 3 },
  "4 Weeks": { weeks: 4 },
  "Let me specify": { weeks: 0 },
  "I'm not sure - indefinite": { weeks: -1 },
};

export default function FreeTrialForm({ afterSubmit, headingClassNames }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const firstNameInputRef = useRef(null);
  const [freezeStartDate, setFreezeStartDate] = useState(null);
  const [showEndFreezeDate, setShowEndFreezeDate] = useState(false);
  const [freezeEndDate, setFreezeEndDate] = useState(null);
  const [freezeEndDateText, setFreezeEndDateText] = useState(null);
  const [freezeReason, setFreezeReason] = useState(null);
  const [showFreezeReasonOther, setShowFreezeReasonOther] = useState(false);
  const { ref: firstNameRef, ...firstNameRest } = register("firstName", {
    required: true,
  });
  const [freezeWeeks, setFreezeWeeks] = useState(null);
  const locationData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "location-page" } } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              location
              sub_category
              slug
            }
          }
        }
      }
    }
  `);
  const locations = locationData.allMarkdownRemark.edges;

  const [locationSelected, setLocationSelected] = useState(null);

  useEffect(() => {
    firstNameInputRef.current.focus();
  }, []);
  useEffect(() => {
    setShowFreezeReasonOther(freezeReason === "Other");
  }, [freezeReason]);

  useEffect(() => {
    var outputText = null;
    var endDate = null;
    setShowEndFreezeDate(false);
    if (freezeWeeks) {
      const { weeks } = freezeOptions[freezeWeeks];
      if (weeks >= 1 && freezeStartDate) {
        endDate = new Date(freezeStartDate);
        endDate.setDate(freezeStartDate.getDate() + weeks * 7);
        if (endDate < freezeStartDate) {
          outputText = null;
          console.log("Something went odd here trying to calculate");
          console.debug(freezeStartDate.getDate());
          console.debug(endDate);
          console.debug(endDate.toLocaleDateString());
        } else {
          outputText = `Back to training from ${endDate.toLocaleDateString()}`;
        }
      } else if (weeks === 0) {
        outputText = null;
        setShowEndFreezeDate(true);
      } else if (weeks === -1) {
        outputText = null;
      }
    }
    setFreezeEndDate(endDate);
    setFreezeEndDateText(outputText);
  }, [freezeWeeks, freezeStartDate]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    gaEvent("Form", "Submit", "Member Freeze Submit");
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form_name,
        ...data,
        freezeEndDate: freezeEndDate,
      }),
    })
      .then(afterSubmit)
      .catch((error) => alert(error));
  };
  const onError = (errors, e) => console.error(errors, e);

  const headingClassName =
    headingClassNames ||
    "text-2xl text-heading uppercase text-center leading-6 font-medium text-gray-900";

  const constantInputClassName = "shadow-sm block w-full sm:text-md ";

  const inputClassName =
    constantInputClassName +
    "focus:ring-feat-blue-500 focus:border-feat-blue-500 border-gray-300 placeholder-feat-darkgrey-300";

  const errorInputClassName =
    constantInputClassName +
    "focus:ring-red-500 focus:border-red-500 border-red-300 placeholder-red-300";

  const FreezeOption = ({ freezeText, weeks }) => (
    <Listbox.Option
      key={freezeText}
      className={({ active }) =>
        classNames(
          active ? "text-white bg-feat-blue" : "text-gray-900",
          "cursor-default select-none relative py-2 pl-3 pr-9"
        )
      }
      value={freezeText}
    >
      {({ active }) => (
        <>
          <div className="flex">
            <span
              className={classNames(
                active ? "font-semibold" : "font-normal",
                "truncate"
              )}
            >
              {freezeText}
            </span>
          </div>
        </>
      )}
    </Listbox.Option>
  );

  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={handleSubmit(onSubmit, onError)}
      name={form_name}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={form_name} />
      <input {...register("bot-field")} type="hidden" />
      <div className="">
        <div>
          <h1 className={headingClassName}>Freeze Membership</h1>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <input
              type="text"
              {...firstNameRest}
              autoComplete="given-name"
              placeholder="First Name"
              className={
                errors["firstName"] ? errorInputClassName : inputClassName
              }
              ref={(e) => {
                firstNameRef(e);
                firstNameInputRef.current = e;
              }}
            />
          </div>

          <div className="sm:col-span-3">
            <input
              type="text"
              {...register("lastName", { required: true })}
              autoComplete="family-name"
              placeholder="Last Name"
              className={
                errors["lastName"] ? errorInputClassName : inputClassName
              }
            />
          </div>
          <div className="sm:col-span-6">
            <PhoneInput
              name="mobile"
              control={control}
              rules={{ required: true }}
              className={
                errors["mobile"] ? errorInputClassName : inputClassName
              }
              defaultCountry="AU"
              type="tel"
              autoComplete="tel"
              placeholder="Mobile"
            />
          </div>
          <div className="sm:col-span-6">
            <input
              type="hidden"
              name="locationsChoice"
              value={locationSelected}
            />
            <Controller
              name="locationsChoice"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Listbox
                  value={locationSelected}
                  onChange={(e) => {
                    onChange(e.frontmatter.location);
                    setLocationSelected(e);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button
                      className={classNames(
                        "relative w-full bg-white border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-feat-blue focus:border-feat-blue sm:text-md",
                        errors["locationsChoice"]
                          ? "border-red-300 focus:ring-red focus:border-red"
                          : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                      )}
                    >
                      <span className="w-full inline-flex truncate">
                        {locationSelected ? (
                          <>
                            <span className="truncate">
                              {locationSelected?.frontmatter.location}
                            </span>
                            <span className="ml-2 truncate text-feat-darkgrey">
                              {locationSelected?.frontmatter.sub_category}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className={classNames(
                                "truncate",
                                errors["locationsChoice"]
                                  ? "text-red-300"
                                  : " text-feat-darkgrey-300"
                              )}
                            >
                              Select your Training Location
                            </span>
                          </>
                        )}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {locations.map(({ node: location }) => (
                          <Listbox.Option
                            key={location.frontmatter.location}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-feat-blue"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={location}
                          >
                            {({ locationSelected, active }) => (
                              <>
                                <div className="flex">
                                  <span
                                    className={classNames(
                                      locationSelected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "truncate"
                                    )}
                                  >
                                    {location.frontmatter.location}
                                  </span>
                                  <span
                                    className={classNames(
                                      active
                                        ? "text-feat-blue-50"
                                        : "text-feat-darkgrey",
                                      "ml-2 truncate"
                                    )}
                                  >
                                    {location.frontmatter.sub_category}
                                  </span>
                                </div>

                                {locationSelected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-feat-blue",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
            />
          </div>
          <div className="sm:col-span-6">
            <input type="hidden" name="freezeWeeks" value={freezeWeeks} />
            <Controller
              name="freezeWeeks"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Listbox
                  value={freezeWeeks}
                  onChange={(e) => {
                    onChange(e);
                    setFreezeWeeks(e);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button
                      className={classNames(
                        "relative w-full bg-white border shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1  sm:text-md",
                        errors["freezeWeeks"]
                          ? "border-red-300 focus:ring-red focus:border-red"
                          : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                      )}
                    >
                      <span className="w-full inline-flex truncate">
                        {freezeWeeks ? (
                          <span className="truncate">{freezeWeeks}</span>
                        ) : (
                          <span
                            className={classNames(
                              "truncate",
                              errors["freezeWeeks"]
                                ? "text-red-300"
                                : " text-feat-darkgrey-300"
                            )}
                          >
                            How long would you like to freeze?
                          </span>
                        )}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {Object.keys(freezeOptions).map((key) => (
                          <FreezeOption
                            freezeText={key}
                            weeks={freezeOptions[key].week}
                          />
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
            />
          </div>
          <div className="sm:col-span-3">
            <input
              type="hidden"
              name="freezeStartDate"
              value={freezeStartDate}
            />
            <Controller
              name="freezeStartDate"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <DatePicker
                  selected={freezeStartDate}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  onChange={(e) => {
                    setFreezeStartDate(e);
                    onChange(e);
                  }}
                  onBlur={() => {
                    onBlur();
                  }}
                  className={
                    errors["freezeStartDate"]
                      ? errorInputClassName
                      : inputClassName
                  }
                  placeholderText="Start freeze from"
                  inputRef={ref}
                />
              )}
            />
          </div>
          <div className="sm:col-span-3">
            <div className={!showEndFreezeDate && "hidden"}>
              <input type="hidden" name="freezeEndDate" value={freezeEndDate} />
              <Controller
                name="freezeEndDate"
                control={control}
                rules={{ required: showEndFreezeDate }}
                render={({ field: { onChange, onBlur, ref } }) => (
                  <DatePicker
                    selected={freezeEndDate}
                    minDate={freezeStartDate || new Date()}
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => {
                      setFreezeEndDate(e);
                      onChange(e);
                    }}
                    onBlur={() => {
                      onBlur();
                    }}
                    className={classNames(
                      errors["freezeEndDate"]
                        ? errorInputClassName
                        : inputClassName,
                      !showEndFreezeDate && "hidden"
                    )}
                    placeholderText="End freeze from"
                    inputRef={ref}
                  />
                )}
              />
            </div>
            <div className="-mt-1">{freezeEndDateText}</div>
          </div>
          <div className="sm:col-span-6">
            <input type="hidden" name="freezeReason" value={freezeReason} />
            <Controller
              name="freezeReason"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Listbox
                  value={freezeReason}
                  onChange={(e) => {
                    onChange(e);
                    setFreezeReason(e);
                  }}
                >
                  <div className="relative">
                    <Listbox.Button
                      className={classNames(
                        "relative w-full bg-white border shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1  sm:text-md",
                        errors["freezeReason"]
                          ? "border-red-300 focus:ring-red focus:border-red"
                          : "border-gray-300 focus:ring-feat-blue focus:border-feat-blue"
                      )}
                    >
                      {" "}
                      <span className="w-full inline-flex truncate">
                        {freezeReason ? (
                          <span className="truncate">{freezeReason}</span>
                        ) : (
                          <span
                            className={classNames(
                              "truncate",
                              errors["freezeReason"]
                                ? "text-red-300"
                                : " text-feat-darkgrey-300"
                            )}
                          >
                            What's your reason for freezing?
                          </span>
                        )}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {freeze_reasons.map((freeze_reason) => (
                          <Listbox.Option
                            key={freeze_reason}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-feat-blue"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={freeze_reason}
                          >
                            {({ active }) => (
                              <>
                                <div className="flex">
                                  <span
                                    className={classNames(
                                      active ? "font-semibold" : "font-normal",
                                      "truncate"
                                    )}
                                  >
                                    {freeze_reason}
                                  </span>
                                </div>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              )}
            />
          </div>
          <div
            className={classNames(
              "sm:col-span-6",
              !showFreezeReasonOther && "hidden"
            )}
          >
            <input
              type="text"
              {...register("freezeReasonOther", {
                required: showFreezeReasonOther,
              })}
              placeholder="Let us know why here"
              className={
                errors["freezeReasonOther"]
                  ? errorInputClassName
                  : inputClassName
              }
            />
          </div>
        </div>
      </div>

      <div className="pt-3">
        <div className="">
          <Button
            type="submit"
            className="w-full uppercase font-heading text-center text-white bg-feat-blue hover:bg-feat-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue"
          >
            Submit Freeze Request
          </Button>
        </div>
        <p className="font-light italic text-center text-sm mt-2">
          Forms submitted after 2pm on a Friday will not be processed until next
          week.
        </p>
      </div>
    </form>
  );
}
