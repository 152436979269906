import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import StepsToStart from "../../sections/StepsToStart";
import FreezeForm from "../../components/Forms/FreezeForm";
import OurLocations from "../../sections/OurLocations";
import { navigate } from "gatsby-link";

const FreezeMembershipPage = () => {
    const successSubmitted = () => {
        navigate("/feat-members/submitted");
    };
    return (
        <Layout>
            <Seo
                title="Freeze Membership | FEAT Fitness"
                meta_title="Freeze Membership | FEAT Fitness"
                meta_desc="As a FEAT Member, you can freeze your memberships as needed when you are unable to train! FEAT Fitness is fun community training in your local outdoor park."
                slug="feat-members/freeze-membership"
            />
            <main>
                <div>
                    <div className="bg-feat-lightgrey">
                        <div className="max-w-lg mx-auto py-24 px-6">
                            <FreezeForm
                                headingClassNames="text-4xl text-heading uppercase text-center leading-6 font-medium text-gray-900 pb-4"
                                afterSubmit={successSubmitted}
                            />
                        </div>
                    </div>

                    {/* Map and Location Details */}
                    <OurLocations />
                    {/* END Map and Location Details */}

                    {/* Instagram Feed */}
                    <InstagramFeed />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
};

export default FreezeMembershipPage;
